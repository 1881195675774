// Home Page Code //
var compare = compare || {};
var review = review || {};

/***********************************************************
 * COMPARE LOGIC
 **********************************************************/

compare.fn = {
    toggle: function (ev) {
        var checkbox = $(ev.target) || $(ev.srcElement);
        var id = checkbox.attr('value');
        if (checkbox.is(':checked')) {
            compare.fn.add(id)
        } else {
            compare.fn.remove(id)
        }
    },
    add: function (id) {
        var element = $('<input type="hidden" name="compareThis[]">');
        element.attr('id', id).attr('value', id);
        $('#compareStage').append(element);
        this.check();
    },
    remove: function (id) {
        var element = $('input[id=' + id + ']');
        if (typeof element != 'undefined') {
            element.remove();
            this.check();
        }
    },
    check: function () {
        var count = $("#compareStage > input").length;
        if (count > 1) {
            $('#compare-link').addClass('show');
            return true;
        } else {
            $('#compare-link').removeClass('show');
            return false;
        }
    },
    submit: function (ev) {
        ev.preventDefault();
        if (compare.fn.check) {
            $('#compareStage').submit();
        }
    },
    clear: function () {
        $('.compare input').each(function () {
            $('.compare input').prop('checked', false);
        });
        $("#compareStage > input").each(function () {
            $("#compareStage > input").remove();
        });
        this.check();
    },
    init: function () {
        var count = $("input:checkbox:checked").length;
        if (count > 0) {
            $("input:checkbox:checked").each(function () {
                var id = $(this).attr('value');
                compare.fn.add(id);
            });
        }
    }
};

$('.compare').click(compare.fn.toggle);
$('#compareDo').click(compare.fn.submit);


$(document).ready(function () {
    compare.fn.init();

    /***********************************************************
     * CONTACT FORM
     **********************************************************/

    // When submit button is clicked
    $('#contactSubmitButton').on('click', function (e) {
        e.preventDefault();

        var data = {};
        var errors = [];
        var errorsContainer = $('#errorsContainer');
        var name = $('#name').val();
        var email = $('#email').val();
        var message = $('#message').val();

        // Make sure we have a value for each input
        if (name != "") {
            data['name'] = name;
        }
        else {
            errors.push("Your name is required.");
        }

        if (email != "") {
            data['email'] = email;
        }
        else {
            errors.push("Your email address is required.");
        }

        if (message != "") {
            data['message'] = message;
        }
        else {
            errors.push("A message is required.");
        }

        // Display any errors
        if (errors.length != 0) {
            var html = "";
            $(errors).each(function () {
                var li = "<li>";
                li += this;
                li += "</li>";

                html += li;
            });

            $(errorsContainer).show();

            $("#errorsContainer ul").html(html);
        }
        // Else post the form
        else {
            function postSuccess() {
                errorsContainer.remove();
                $('#name').val("");
                $('#email').val("");
                $('#message').val("");
                $('#formSuccessMessage').addClass('show');
            }

            $.ajax({
                type: "POST",
                url: '/contact/post',
                data: data,
                success: postSuccess(),
                dataType: JSON
            });
        }

    });


    /***********************************************************
     * MOBILE MENU
     **********************************************************/

    var bars = $('[data-bars]'),
        menuOpen = false,
        contentWrapper = $('.content-wrapper'),
        header = $('.header-block'),
        menu = $('.offcanvas-menu-block');

    contentWrapper.on('click', function (event) {
        if (menuOpen) {
            event.stopPropagation();
            toggleMenu();
        }
    });

    bars.on('click', function (event) {
        event.stopPropagation();
        toggleMenu();
    });

    function toggleMenu() {

        if (!contentWrapper.attr('data-open')) {
            contentWrapper.attr('data-open', true)
        } else {
            contentWrapper.removeAttr('data-open');
        }
        if (!menu.attr('data-open')) {
            menu.attr('data-open', true)
        } else {
            menu.removeAttr('data-open');
        }
        if (!bars.attr('data-open')) {
            bars.attr('data-open', true);
        } else {
            bars.removeAttr('data-open');
        }
        if (!header.attr('data-open')) {
            header.attr('data-open', true);
        } else {
            header.removeAttr('data-open');
        }
        menuOpen = !menuOpen;
    }


    /***********************************************************
     * STICKY HEADER
     **********************************************************/

    var scrollTop = $(window).scrollTop();

    $(window).scroll(function () {
        headerScroll();
    });

    function headerScroll() {
        if ($(window).scrollTop() !== scrollTop) {
            header.attr('data-scrolled', true);
        } else {
            header.removeAttr('data-scrolled');
        }
    }


    /***********************************************************
     * MODALS
     **********************************************************/

        // Get all modals on the page
    var modals = $('[data-modal]');
    var bodyElement = $('body');
    var modalShown = 0;

    // For each modal on the page
    modals.each(function () {
        var modalElement = $(this);

        // If parent of the modal is not the body
        if (!modalElement.parent().is(bodyElement)) {
            // Move modal to the body
            modalElement.appendTo(bodyElement);
        }
    });

    // Add overlay element if there isn't already one
    if (bodyElement.children('.modal__overlay').length < 1) {
        bodyElement.append('<div class="modal__overlay"></div>');
    }

    // Set up reveal modal click handlers
    $(document).on('click', '[data-modal-reveal]', function () {
        var clickedElement = $(this);
        var modalId = clickedElement.attr('data-modal-reveal');
        var modalElement = $('#' + modalId);
        modalElement.addClass('modal--visible');
        bodyElement.addClass('body--modal-open');
    });

    // Set up close modal click handlers
    $(document).on('click', '[data-modal-close]', function () {
        var clickedElement = $(this);
        // Find modal element
        var modalElement = clickedElement.parents('.modal');

        if (modalElement.length < 1) {
            console.error('Close modal trigger not inside a modal.', clickedElement);
            return false;
        }

        // Remove visible class
        modalElement.removeClass('modal--visible');
        bodyElement.removeClass('modal-open');
    });

    // Set up close modal listener on overlay

    $(document).on('click', '.modal__overlay', function () {
        $('[data-modal].modal--visible').removeClass('modal--visible');
        bodyElement.removeClass('modal-open');
    });

    $(document).on('mouseleave', function(e) {

      // If they haven't moused out the top of the page
      if (e.clientY > 0)
      {
        // Don't do anything
        return;
      }

      var modalRevealSelector = $('body').attr(
        'data-mouseleave-modal-selector');

      if (!modalRevealSelector)
      {
        return;
      }

      var modal = $(modalRevealSelector);

      if (!modal)
      {
        console.log('Cannot reveal modal with selector: ', modalRevealSelector);
      }

      modal.addClass('modal--visible');
    });


    /***********************************************************
     * EXIT POP
     **********************************************************/

    var exitPopModal = $('#exitModal');

    if (exitPopModal.length) {
        $(document, window, 'html').on('mouseleave', function (event) {

            var relativePosition = event.pageY - $(document).scrollTop();

            if (relativePosition <= 20) {

                if (modalShown === 0) {
                    console.log('ping 1');
                    modalShown = 1;
                    exitPopModal.addClass('modal--visible');
                    bodyElement.addClass('modal-open');
                }
                else if (modalShown === 1) {
                    console.log('ping 2');
                    modalShown = 2;
                    exitPopModal.addClass('modal--visible');
                    bodyElement.addClass('modal-open');
                }
            }

        });

        // $(document).on('mouseleave', function() {
        //   if(event.offsetY <= 0)
        //   {
        //     console.log('ping:' + event.offsetY);
        //     if(modalShown === 0)
        //     {
        //       console.log('ping 1');
        //       modalShown = 1;
        //       exitPopModal.addClass('modal--visible');
        //       bodyElement.addClass('modal-open');
        //     }
        //     else if (modalShown === 1){
        //       console.log('ping 2');
        //       modalShown = 2;
        //       exitPopModal.addClass('modal--visible');
        //       bodyElement.addClass('modal-open');
        //     }
        //   }
        // })
    }


    /***********************************************************
     * ADVERTISING DISCLOSURE
     **********************************************************/

    var adDisclosureBubble = $('.bubble-pop-out');

    $('.filter-bar__aligned-link').on('click', function (event) {
        event.stopPropagation();
        adDisclosureBubble.addClass('show');
    });

    $('.content-wrapper').on('click', function () {
        adDisclosureBubble.removeClass('show');
    });

    /***********************************************************
     * PRODUCT BLOCK EXIT LINKS
     **********************************************************/

    // Open exit link when a product block is clicked
    // Any elements within it that are wrapped in the .ignore class
    // will be ignored and work as normal
    // $('[data-products-container]').delegate('.product-block[data-href-new] *', 'click', function (event) {
    //     event.stopPropagation();
    //     var clickedElement = $(this);
    //     // Get any parent elements of clicked element that have .ignore class on them
    //     var ignoreParent = clickedElement.parents('.ignore');
    //     var elementShouldBeIgnored = clickedElement.hasClass('ignore');
    //
    //     var hasIgnoreParent = (ignoreParent.length > 0);
    //
    //     // If it doesn't have an ignore parent && it doesn't have the ignore class
    //     if (!hasIgnoreParent && !elementShouldBeIgnored) {
    //         // If the clicked element is not wrapped in a .ignore class
    //         // Get the containing product block
    //         var productBlock = clickedElement.parents('.product-block[data-href-new]');
    //         // Get the href we want to open, and open it
    //         window.open(productBlock.data('href-new'));
    //     }
    // });

    /***********************************************************
     * PRODUCT FILTER
     **********************************************************/

        // Sort select menu
    var sortSelectInput = $('[data-sort-select]');
    // Get all select inputs we want to filter by
    var filterSelectInputs = $('[data-filter-select]');
    var productBlocksContainer = $('[data-products-container]');
    var productBlocks = $('.product-block');

    var compareProducts = function (sortType) {

        return function (productBlockA, productBlockB) {
            var productASortValue = productBlockA.getAttribute('data-sort-' + sortType);
            var productBSortValue = productBlockB.getAttribute('data-sort-' + sortType);

            // Reverse the comparison for popular
            if (sortType === 'popular') {
                return productBSortValue - productASortValue;
            }

            return productASortValue - productBSortValue;
        }

    };

    var sortProducts = function () {

        var sortType = sortSelectInput.val();

        var sortText = sortSelectInput.find(':selected').attr('data-text');
        $('[data-sort-text]').text(sortText);
        var unfiltered = productBlocks.filter('[data-filtered=false]');

        // Sort the unfiltered ones
        unfiltered.sort(compareProducts(sortType));

        // Push filtered ones to the end, as CSS nth child is used to hide them
        var filtered = productBlocks.filter('[data-filtered=true]');
        productBlocks = $.merge(unfiltered, filtered);

        // Set initial rank
        var rank = 1;

        // For each product block that has the --show modifier class
        productBlocks
        // .filter('[data-filtered=false]') // so, as sorting will not show some alrady cut off orids
            .each(function () {
                // Update the contents of the element with the rank
                var rankText = $('[data-product-rank-inner]', $(this));
                rankText.text(rank);
                rank++;
            });

        productBlocksContainer.html(productBlocks);

        // Where we have removed the product blocks from the page, all previous event listeners have been cleared
        // This will add the click handlers back on the .compare elements in each product block
        productBlocks
            .find('.compare')
            .on('click', compare.fn.toggle);

        return productBlocks;
    };

    /**
     *
     * @returns {boolean}
     */
    var filterProducts = function () {

        var topFilterFoundNoneText = $('#topFilterFoundNoneText');
        topFilterFoundNoneText.hide();


        var dataAttributes = [];

        // For each filter select input
        filterSelectInputs.each(function () {

            // Get the current iteration
            var input = $(this);
            // Get the value of the input
            var inputValue = input.val();

            if (inputValue === 'default') {
                // Skip to next iteration
                return;
            }

            // Get the input name
            var inputName = input.attr('name');

            // Generate the name of the data attribute
            var dataAttribute = 'data-' + inputName + '-' + inputValue;

            // Add to data attributes array
            dataAttributes.push(dataAttribute);
        });

        // If no options have been selected from the filter dropdowns
        if (!dataAttributes.length) {
            // Add show modifier class to all products and return
            // productBlocks.addClass(productBlockShowModifierClass);
            productBlocks.show().attr('data-filtered', false);

            return true;
        }

        // Build selector of all products to show
        var selector = '';
        for (var key in dataAttributes) {
            var value = dataAttributes[key];

            selector += '[' + value + ']';
        }

        // Get all elements that are currently shown
        var productBlocksToShow = $(selector, productBlocksContainer);

        // productBlocks.removeClass(productBlockShowModifierClass);
        productBlocks
            .hide()
            .attr('data-filtered', true);

        // productBlocksToShow.addClass(productBlockShowModifierClass);
        productBlocksToShow
            .show()
            .attr('data-filtered', false);

        if (productBlocksToShow.length === 0) {
            topFilterFoundNoneText.show();
            $('[data-sort-recommended=1]')
                .show()
                .attr('data-filtered', false);
        }
    };

    var populateResultsCount = function () {

        var countTextSpan = $('[data-count]');
        var count = $('[data-filtered=false]').length;

        var topFilterFoundNoneText = $('#topFilterFoundNoneText');
        if (topFilterFoundNoneText.is(":visible")) {
            count = 0;
        }
        countTextSpan.text(count);
    };

    // When any of those select inputs change
    filterSelectInputs.on('change', function () {
        productBlocksContainer.hide();
        filterProducts();
        sortProducts();
        populateResultsCount();
        reInitStuff();
        compare.fn.clear();
        productBlocksContainer.fadeIn(400);
    });

    // Filter reset
    $('[data-filter-reset]').click(
        function (e) {
            e.preventDefault();
            productBlocksContainer.hide();
            filterSelectInputs.prop("selectedIndex", 0);
            sortSelectInput.prop("selectedIndex", 0);
            filterProducts();
            sortProducts();
            populateResultsCount();
            reInitStuff();
            productBlocksContainer.fadeIn(400);
        }
    );

    // When sort select input changes
    sortSelectInput.on('change', function () {
        productBlocksContainer.hide();
        sortProducts();
        productBlocksContainer.fadeIn(400);
        reInitStuff();
    });

    var reInitStuff = function () {
        starRating();
    };


    // Setup basics
    var initialiseFilter = function () {
        // None are filtered to start with, so show them all
        productBlocks.attr('data-filtered', false).show();
    };


    // Go!
    initialiseFilter();
    populateResultsCount();
});

/**
 * COOKIE POLICY LOGIC
 */

var cookiePolicy = $('.cookie-policy');
var cookieClose = $('i', cookiePolicy);

$(document).ready(function () {

    // on page lad if the user doesnt have the cookie cookiePolicySeen
    if (typeof $.cookie('cookieNoticeSeen') === 'undefined') {

        // then show the cookie policy message
        cookiePolicy.removeClass('hide');

    }

});

var closeCookiePolicy = function () {

    // if there is a cookie already set then do nothing
    if (typeof $.cookie('cookieNoticeSeen') === 'undefined') {

        // else set the cookie
        $.cookie('cookieNoticeSeen', 1);

        // hide the cookie policy notice
        cookiePolicy.fadeOut();
    }
};

cookiePolicy.on('click', function () {
    closeCookiePolicy();
});
// on click of the accept button
cookieClose.on('click', function () {
    closeCookiePolicy();
});

/**
 * Toggle Filter Bar Logic
 */

var filterBar = $('.filter-bar');
var toggleFilterButton = $('#toggle-filters');

toggleFilterButton.on('click', function () {

    if (filterBar.hasClass('filter-bar--hide')) {
        filterBar.removeClass('filter-bar--hide');
    }
    else {
        filterBar.addClass('filter-bar--hide');
    }

});

$(document).ready(
    function () {
        starRating();
        fixedStarRating();
    }
);

function starRating() {
    $('.live-star').each(
        function () {
            var star = $(this);
            var readOnly = star.parent().attr('data-rated') === "1";
            var score = star.parent().attr('data-current');

            star.raty('destroy').raty(
                {
                    half: true,
                    score: score,
                    readOnly: readOnly,
                    halfShow: true,
                    hints: [
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        ""
                    ],
                    mouseover: function (score, evt) {

                        var hoverText = {
                            "0.5": "Poor",
                            "1.5": "Fair",
                            "2.5": "Ok",
                            "3.5": "Good",
                            "4.5": "Great",
                            "1": "Fair",
                            "2": "Ok",
                            "3": "Good",
                            "4": "Very Good",
                            "5": "Awesome"
                        };

                        var text = hoverText[score];
                        var context = $(this).parent();
                        $('.text', context).hide();
                        $('.hoverText', context).text(text);
                    },
                    mouseout: function () {
                        var parent = $(this).parent();
                        $('.text', parent).show();
                        $('.hoverText', parent).text('');
                    },
                    click: function (value) {
                        var parent = $(this).parent();
                        parent.animate({opacity: 0.25});
                        $.ajax(
                            {
                                url: "/ajax/rate-product?productId=" + parent.attr('data-pid') + "&starCount=" + value,
                                context: document.body
                            }).done(function (val) {
                            parent.animate({opacity: 1});
                            $('.text', parent).show().text(val + ' Votes, Thanks!');
                            $('.hoverText', parent).text('');
                            $('.live-star', parent).raty('readOnly', true);
                        });
                    }
                });
        }
    );
}

function fixedStarRating() {
    $('.fixed-star').each(
        function () {
            var star = $(this);
            var score = star.parent().attr('data-current');

            star.raty('destroy').raty(
                {
                    half: true,
                    score: score,
                    readOnly: true,
                    halfShow: true,
                    hints: [
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        ""
                    ],
                });
        }
    );
}
